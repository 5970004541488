import DataTable from 'react-data-table-component';

// A super simple expandable component.
const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;

const columns = [
	{
		name: 'Server',
		selector: row => row.title,
	},
	{
		name: 'Status',
		selector: row => row.year,
	},
];

const data = [
  	{
		id: 1,
		title: 'Beetlejuice',
		year: '1988',
	},
	{
		id: 2,
		title: 'Ghostbusters',
		year: '1984',
	},
]

function ServerList() {
	return (
		<DataTable
			columns={columns}
			data={data}
			expandableRows
			expandableRowsComponent={ExpandedComponent}
		/>
	);
};

export default ServerList
