import React from "react"
import ReactModal from 'react-modal';
import { useState } from 'react'

//import { useNavigate } from "react-router-dom";

import ServerList from './serverlist'

const Home = (props) => {
    const { email, authToken } = props                                                                                    
    const [showModal, setShowModal] = useState(false)

    const onCreateServer = () => {
        setShowModal(true);
    }

    const onCancelModal = () => {
        setShowModal(false);
    }

    const onCreateServerModal = () =>
    {
        fetch('https://worker-mc-server.chris-e-green.workers.dev/api/servers', {
            method: 'POST',
            headers: {"CF_Authorization" : authToken},
            body: JSON.stringify({email: email})
        })
        .then((r) => {
          console.log(r)
        })
        setShowModal(false);
    }

    return <div className="mainContainer">
        <div className={"titleContainer"}>
            <div>Minecraft Server Admin</div>
        </div>
        <input className={'inputButton'} type="button" onClick={onCreateServer} value={'Create Server'} />
        <ReactModal
           isOpen={showModal}
           contentLabel="Minimal Modal Example"
        >   
          <input className={'inputButton'} type="button" onClick={onCancelModal} value={'Cancel'}/>
          <input className={'inputButton'} type="button" onClick={onCreateServerModal} value={'Create'} />
        </ReactModal>
        <ServerList />

    </div>
}

export default Home