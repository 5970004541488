import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './home'
//import Check from './check'
import './App.css';
import { useEffect, useState } from 'react'


function listCookies() {
  var theCookies = document.cookie.split(';');
  var aString = '';
  for (var i = 1 ; i <= theCookies.length; i++) {
      aString += i + ' ' + theCookies[i-1] + "\n";
  }
  return aString;
}

function getCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  console.log(listCookies());
  return b ? b.pop() : "";
}

function App() {
  const [email, setEmail] = useState('')
  const [authToken, setAuthToken] = useState(getCookie("CF_Authorization"));

  useEffect(() => {

    if (authToken) {
      // If the token exists, verify it with the auth server to see if it is valid
      fetch('https://worker-mc-server-admin.chris-e-green.workers.dev/validate', {
        method: 'POST',
        headers: {"CF_Authorization" : authToken},
      })
      .then((r) => r.json())
      .then((r) => {
        console.log(r)
        setEmail(r)
      })
    }
  }, [authToken])
    
  // Update token with every render when its value has changed.
  console.log(listCookies());
  const authCookie = getCookie("CF_Authorization");
  if (authToken !== authCookie ) {
    setAuthToken(authCookie);
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home email={email} authToken={authToken} />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
  //<Route path="/check" element={<Check />} />

}

export default App;
